<template>
<div class="main">
  <div class="main_right">
    <VxeBasicTable ref="xGrid" service="/wisdom/repairTypeUser" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: ["currNode", "selectRow"],
  data() {
    return {
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "repairMaster",
              buttonRender: {
                name: '$ToolbarSelectWorker',
                props: {
                  content: "添加报修师傅",
                  status: "primary",
                },
                params: {
                  organizationId : this.currNode.parentId
                },
                events: {
                  click: (params, row) => {
                    this.submitEvent(row);
                  }
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: 'workerName',
            title: '职工名称',
            minWidth: 160,
            editRender: {
              name: 'input'
            }
          },
          {
            field: "positionName",
            title: "所属岗位",
            minWidth: 120,
            editRender: {
              name: "$input",
            },
          },
          {
            field: "phone",
            title: "联系电话",
            minWidth: 120,
            editRender: {
              name: "input"
            },
          },
        ],
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [],
        //表单校验规则
        rules: {
          organizationId: [
            { required: true, message: "请选择所属上级" },
          ],
          name: [
            { required: true, message: "请输入职工名称" },
          ],
          positionId: [
            { required: true, message: "请选择所属岗位" },
          ],
          sex: [
            { required: true, message: "请选择性别" },
          ],
          enabled: [{ required: true, message: "请选择状态" }],
        },
        // 新增功能字段初始化
        data: {
          name: "",
          code: "",
          sex: "1",
          type: "1",
          positionId: "",
          orderCode: "0",
          enabled: "1",
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "workerName",
            title: "职工姓名",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入职工姓名",
              },
            },
          },
          {
            field: "phone",
            title: "联系电话",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入联系电话",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          organizationId: this.selectRow.commumityId,
          parentId: this.selectRow.commumityId,
          repairSettingId: this.selectRow.id,
        },
      },
    };
  },

  methods: {
    ...mapActions(["submitEventService"]),
    submitEvent(row) {
      let params = {
        parentId: this.selectRow.commumityId,
        repairSettingId: this.selectRow.id,
        workerId: row.id,
        workerName: row.name,
        positionName: row.positionName,
        phone: row.phone,
      }
      this.submitEventService({
        service: "/wisdom/repairTypeUser",
        params: params,
      }).then(({ code, data }) => {
        if (code == 200) {
          this.$refs.xGrid.getLists();
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
