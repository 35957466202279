<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
  <vxe-modal ref="xModal" title="选择报修师傅" width="800" height="500" show-zoom>
    <template v-slot>
      <RepairMaster :currNode="currNode" :selectRow="selectRow" />
    </template>
  </vxe-modal>
</div>
</template>

<script>
import RepairMaster from './RepairMaster'
export default {
  components: {
    RepairMaster
  },
  data() {
    return {
      currNode: {},
      selectRow: null,
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "repairTypeName",
            title: "报修项目",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入报修项目",
              },
            },
          },
          {
            field: "repairWay",
            title: "派单方式",
            minWidth: 160,
            editRender: {
              name: "$select",
              optionTypeCode: "sendWay",
              props: {
                maxlength: 24,
                placeholder: "请选择派单方式",
              },
            },
          },
          // {
          //   field: "enabled",
          //   title: "状态",
          //   width: 120,
          //   cellRender: {
          //     name: "$switch",
          //     optionTypeCode: "enabledStatus",
          //     props: {
          //       openLabel: "启用",
          //       closeLabel: "禁用",
          //       openValue: "1",
          //       closeValue: "0"
          //     },
          //     events: {
          //       change: ({ row }) => {
          //         this.$refs.xGrid.submitEvent(row);
          //       }
          //     }
          //   },
          // },
          {
            fixed: "right",
            title: "操作",
            width: 200,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  name: "repairMaster",
                  status: "primary",
                  label: "报修师傅",
                  events: {
                    click: (row) => {
                      this.selectRow = row;
                      this.$refs.xModal.open();
                    }
                  }
                },
                {
                  name: "edit",
                  status: "primary",
                  label: "修改",
                  eventName: "queryEvent",
                },
                {
                  name: "delete",
                  status: "danger",
                  label: "删除",
                  eventName: "removeEvent",
                },
              ],
            },
          },
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "parentId",
            title: "所属上级",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              props: {
                placeholder: "请选择所属上级",
              },
              expandAll: true,
              events: {
                click: (params, node) => {
                  let { data } = params;
                  if (node.type == '1001') {
                    data["parentId"] = node.id;
                  } else {
                    this.$message.error('请选择小区')
                  }
                },
              },
            },
          },
          {
            field: "repairTypeName",
            title: "报修项目",
            span: 24,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入报修项目",
              },
            },
          },
          {
            field: "repairWay",
            title: "派单方式",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "sendWay",
              props: {
                maxlength: 24,
                placeholder: "请选择派单方式",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态",
              },
            },
          },
          {
            field: "remark",
            title: "备注",
            span: 24,
            itemRender: {
              name: "$textarea",
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: "请输入备注内容",
              },
            },
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          parentId: [{ required: true, message: "请选择所属上级" }],
          repairTypeName: [{ required: true, message: "请输入类型名称" }],
          repairWay: [{ required: true, message: "请选择派单方式" }],
          enabled: [{ required: true, message: "请选择状态" }],
        },
        // 新增功能字段初始化
        data: {
          parentId: "",
          repairTypeName: "",
          repairWay: "1",
          enabled: "1",
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "repairTypeName",
            title: "报修项目",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入报修项目",
              },
            },
          },
          {
            field: "repairWay",
            title: "派单方式",
            itemRender: {
              name: "$select",
              optionTypeCode: "sendWay",
              props: {
                maxlength: 24,
                placeholder: "请选择派单方式",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "选择状态",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          repairTypeName: "",
          repairWay: "",
          enabled: ""
        },
      },
    };
  },

  methods: {
    handleNodeClick(treeData, node) {
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.currNode = node;
      this.searchOptions.data["parentId"] = node.id;
      this.formOptions.data["parentId"] = node.id;
      this.$refs.xGrid.getLists();
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
